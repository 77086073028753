import "./App.css";
import Nav from "./components/Nav/Nav";
import Hero from "./components/Hero/Hero";
import SobreMi from "./components/SobreMi/SobreMi";
import Descuento from "./components/Descuento/Descuento";
import ServiciosContainer from "./components/ServiciosContainer/ServiciosContainer";
import Footer from "./components/Footer/Footer";
import Contacto from "./components/Contacto/Contacto";
import useDeviceSize from "./components/Resize/Resize";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

function App() {
  const [isMobile, setIsMobile] = useState(true);
  const arrayWidth = useDeviceSize();
  const width = arrayWidth[0];

  useEffect(() => {
    if (width >= 425) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width, isMobile]);

  return (
    <div className="totalContainer">
      <Nav />
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        <Hero />
      </motion.div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        <Descuento />
      </motion.div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        <ServiciosContainer />
      </motion.div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        <SobreMi />
      </motion.div>
      <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}>
        <Contacto />
      </motion.div>
      <Footer />
    </div>
  );
}

export default App;
