import "./Button.css"
import play from "./Assets/play.png"

const Button = () => {

    if (window.innerWidth <= 425) {
        return (
            <div>
                <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5491160467063" className="mobile_btn">
                    <p className="mobile_btn_content">QUIERO COMENZAR</p>
                    <img className="mobile_btn_icon" src={play} alt="Logo play" />
                </a>
            </div>
        );
    }

    return (
        <div>
            <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5491160467063" className="btn">
                <p className="btn_content">QUIERO COMENZAR</p>
                <img className="btn_icon" src={play} alt="Logo play" />
            </a>
        </div>
    )
}
export default Button