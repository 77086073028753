import "../Hero/Hero.css";
import triangles from "./Assets/grafico1TriangulosColores.svg";
import trianguloRojo from "./Assets/grafico2Triangulorojo.svg";
import trianguloBlanco from "./Assets/grafico3TrianguloBlanco.svg";

const Hero = () => {

  if (window.innerWidth <= 425) {
    return (
      <section className="mobile_heroContainer">
        <div className="mobile_flex">
          <h2 className="mobile_heroTitle">
            OPTIMIZÁ <br />
            <span>TU TIEMPO</span>
          </h2>
          <h3 className="mobile_heroSubtitle">GENEREMOS HÁBITOS DE ENTRENAMIENTO</h3>
          <img
            className="mobile_triangulo_blanco"
            src={trianguloBlanco}
            alt="decoration"
          />
        </div>
      </section>
    );
  }

  return (
    <section className="heroContainer">
      <div className="flex">
        <h2 className="heroTitle">
          OPTIMIZÁ <br />
          <span>TU TIEMPO</span>
        </h2>
        <h3 className="heroSubtitle">GENEREMOS HÁBITOS DE ENTRENAMIENTO</h3>
        <img className="triangles" src={triangles} alt="decoration" />
        <img className="triangulo_rojo" src={trianguloRojo} alt="decoration" />
        <img
          className="triangulo_blanco"
          src={trianguloBlanco}
          alt="decoration"
        />
      </div>
    </section>
  );
};

export default Hero;