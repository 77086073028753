import "../Descuento/Descuento.css";
import imagen from "./Assets/graficoPromos.svg";
import { Link } from "react-scroll";


const Descuento = () => {

  if (window.innerWidth <= 425) {
    return (
      <section className="mobile_container" id="servicios">
        <div>
          <h3 className="mobile_title">
            LOGRÁ TUS OBJETIVOS DE FORMA<span>EFECTIVA</span>
          </h3>
          <div className="mobile_sub-container">
            <li className="mobile_lista">
              <ul className="mobile_lista_descuento">
                <Link to="coachingOnline" spy={true} smooth={true} offset={-25} duration={700} className="mobile_lista_descuento-item">COACHING ONLINE</Link>
              </ul>
              <ul className="mobile_lista_descuento">
                <Link to="preparacionDeportiva" spy={true} smooth={true} offset={-25} duration={700} className="mobile_lista_descuento-item">PREPARACIÓN DEPORTIVA</Link>
              </ul>
              <ul className="mobile_lista_descuento">
                <Link to="personalTraining" spy={true} smooth={true} offset={-25} duration={700} className="mobile_lista_descuento-item">PERSONAL TRAINING</Link>
              </ul>
            </li>
          </div>
          <div className="mobile_oferta">
            <div className="mobile_imagen_container">
              <img className="mobile_imagen" src={imagen} alt="descuento" />
              <h3 className="mobile_imagen_title">PROMO <br /> BIENVENIDA</h3>
              <p className="mobile_imagen_subtitle">PRIMERA CLASE (VIRTUAL O PRESENCIAL) SIN CARGO</p>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="container" id="servicios">
      <h3 className="title">
        LOGRÁ TUS OBJETIVOS DE FORMA<span>EFECTIVA</span>
      </h3>
      <div className="sub-container">
        <li className="lista">
          <ul className="lista_descuento">
            <Link to="coachingOnline" spy={true} smooth={true} offset={-25} duration={700} className="lista_descuento-item">COACHING ONLINE</Link>
          </ul>
          <ul className="lista_descuento">
            <Link to="preparacionDeportiva" spy={true} smooth={true} offset={-25} duration={700} className="lista_descuento-item">PREPARACIÓN DEPORTIVA</Link>
          </ul>
          <ul className="lista_descuento">
            <Link to="personalTraining" spy={true} smooth={true} offset={-25} duration={700} className="lista_descuento-item">PERSONAL TRAINING</Link>
          </ul>
        </li>
        <div className="imagen_container">
          <img className="imagen" src={imagen} alt="descuento" />
          <h3 className="imagen_title">PROMO <br /> BIENVENIDA</h3>
          <p className="imagen_subtitle">PRIMERA CLASE (VIRTUAL O PRESENCIAL) SIN CARGO</p>
        </div>
      </div>
    </section>
  );
};

export default Descuento;
