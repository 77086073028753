import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "./Slider.css";
import "swiper/css";

const Slider = () => {
  const imagenes = [
    "./Assets/certificado01.png",
    "./Assets/certificado02.png",
    "./Assets/certificado03.png",
    "./Assets/certificado04.png",
    "./Assets/certificado05.png",
    "./Assets/certificado06.png",
  ];

  if (window.innerWidth <= 425) {
    return (
      <Swiper
        modules={[Autoplay]}
        className="mobile_slider"
        slidesPerView={1}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        loop
      >
        {imagenes.map((item) => (
          <SwiperSlide className="mobile_slide" key={item}>
            <img src={require(`${item}`)} alt="certificado" />
          </SwiperSlide>
        ))}
      </Swiper>
    );
  }

  return (
    <Swiper
      className="slider"
      slidesPerView={3}
      loop
      modules={[Autoplay]}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
    >
      {imagenes.map((item) => (
        <SwiperSlide className="slide">
          <img src={require(`${item}`)} alt="certificado" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Slider;
