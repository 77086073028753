import "./Footer.css";
import logo from "./Assets/logo.svg";
import logoFb from "./Assets/Fb.svg";
import logoIg from "./Assets/IG.svg";
import logoTt from "./Assets/TikTok.svg";
import logoYt from "./Assets/YouTube.svg";
import { Link } from "react-scroll";


const Footer = () => {

  if (window.innerWidth <= 425) {
    return (
      <footer className="mobile_footer">
        <div className="mobile_footer_top">
          <div className="footer_top-socialNetworks--title">
            <p className="footer_top-socialNetworks--title---title">
              MIS REDES SOCIALES
            </p>
          </div>
          <div className="mobile_footer_top-socialNetworks--socialNetworks">
            <a
              className="facebook"
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/facu.trainerfit"
            >
              <img src={logoFb} alt="Logo Facebook" />
            </a>
            <a
              className="instagram"
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/facu.trainerfit/?igshid=YmMyMTA2M2Y%3D"
            >
              <img src={logoIg} alt="Logo Instagram" />
            </a>
            <a
              className="tiktok"
              rel="noreferrer"
              target="_blank"
              href="https://www.tiktok.com/@facu.trainerfit?_t=8aNLDjqI4LF&_r=1&fbclid=PAAaZHRxDiKy93ku5KAvnpsjRQX-wHLkOve3OP0FABzhTQsF8v5rgzq9geork"
            >
              <img src={logoTt} alt="Logo TikTok" />
            </a>
            <a
              className="youtube"
              rel="noreferrer"
              target="_blank"
              href="https://www.youtube.com/@facu.trainerfit1103"
            >
              <img src={logoYt} alt="Logo Youtube" />
            </a>
          </div>
        </div>
        <div className="footer_stick"></div>
        <div className="footer_bottom">
          <p className="footer_bottom-text">© 2023 Facu Trainer</p>
        </div>
      </footer>
    );
  }

  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="footer_top-logo">
          <img className="footer_top-logo--img" src={logo} alt="Logo footer" />
        </div>
        <div className="footer_top-socialNetworks">
          <div className="footer_top-socialNetworks--title">
            <p className="footer_top-socialNetworks--title---title">
              MIS REDES SOCIALES
            </p>
          </div>
          <div className="footer_top-socialNetworks--socialNetworks">
            <a
              className="facebook"
              rel="noreferrer"
              target="_blank"
              href="https://www.facebook.com/facu.trainerfit"
            >
              <img src={logoFb} alt="Logo Facebook" />
            </a>
            <a
              className="instagram"
              rel="noreferrer"
              target="_blank"
              href="https://www.instagram.com/facu.trainerfit/?igshid=YmMyMTA2M2Y%3D"
            >
              <img src={logoIg} alt="Logo Instagram" />
            </a>
            <a
              className="tiktok"
              rel="noreferrer"
              target="_blank"
              href="https://www.tiktok.com/@facu.trainerfit?_t=8aNLDjqI4LF&_r=1&fbclid=PAAaZHRxDiKy93ku5KAvnpsjRQX-wHLkOve3OP0FABzhTQsF8v5rgzq9geork"
            >
              <img src={logoTt} alt="Logo TikTok" />
            </a>
            <a
              className="youtube"
              rel="noreferrer"
              target="_blank"
              href="https://www.youtube.com/@facu.trainerfit1103"
            >
              <img src={logoYt} alt="Logo Youtube" />
            </a>
          </div>
        </div>
        <div className="footer_top-nav">
          <Link to="servicios" spy={true} smooth={true} offset={-25} duration={700} className="footer_top-nav--anchor">SERVICIOS</Link>
          <Link to="sobreMi" spy={true} smooth={true} offset={-25} duration={700} className="footer_top-nav--anchor">SOBRE MÍ</Link>
          <Link to="contacto" spy={true} smooth={true} offset={-25} duration={700} className="footer_top-nav--anchor">CONTACTO</Link>
        </div>
      </div>
      <div className="footer_stick"></div>
      <div className="footer_bottom">
        <p className="footer_bottom-text">© 2023 Facu Trainer</p>
      </div>
    </footer>
  );
};
export default Footer;
