import Servicios from "../Servicios/Servicios";
import data from "./data.json";

const ServiciosContainer = () => {
  return (
    <section>
      <Servicios {...data.producto1} left />
      <Servicios {...data.producto2} right />
      <Servicios {...data.producto3} left />
    </section>
  );
};

export default ServiciosContainer;
