import React, { useState } from "react";
import "../Nav/Nav.css";
import { Link } from "react-scroll";
import Scroll from "../Scroll/Scroll";
import logo from "./Assets/logo.svg";
import Menu from "./Assets/icon _menu.svg";
import MenuClose from "./Assets/icon _close.svg";
import logoFb from "../Footer/Assets/Fb.svg";
import logoIg from "../Footer/Assets/IG.svg";
import logoTt from "../Footer/Assets/TikTok.svg";
import logoYt from "../Footer/Assets/YouTube.svg";

const Nav = () => {
  const [navbar, setNavbar] = useState(false);
  const [menu, setMenu] = useState(false);
  const [rotate, setRotate] = useState(false);

  const toggleMenu = () => {
    setMenu(!menu);
    setRotate(!rotate);
  };

  const changeNav = () => {
    if (window.scrollY <= window.innerHeight) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };

  window.addEventListener(`scroll`, changeNav);
  rotate
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  if (window.innerWidth <= 425) {
    return (
      <section className="mobile_navPosition">
        <div className="mobile_position">
          <div className="mobile_navContainer">
            <img className="mobile_navLogoL" src={logo} alt="logo" />
            <h1 className="mobile_navBrand">
              Facu Luque <br />
              <span>TRAINER</span>
            </h1>
          </div>
          <button onClick={toggleMenu}>
            <img src={Menu} alt="menu" />
          </button>
        </div>
        <div className="mobile_stick"></div>
        <div className={menu ? " mobile_menu-active" : " mobile_menu"}>
          <div>
            <button
              onClick={toggleMenu}
              className="mobile_menu_close-container"
            >
              <img
                className="mobile_menu_close"
                src={MenuClose}
                alt="close menu"
              />
            </button>
            <img className="mobile_navLogoS" src={logo} alt="logo" />
            <ul className="mobile_menu_navLista">
              <li>
                <Link
                  to="servicios"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={700}
                  className="mobile_menu_navLink"
                  onClick={toggleMenu}
                >
                  SERVICIOS
                </Link>
              </li>
              <li>
                <Link
                  to="sobreMi"
                  spy={true}
                  smooth={true}
                  offset={-25}
                  duration={700}
                  className="mobile_menu_navLink"
                  onClick={toggleMenu}
                >
                  SOBRE MÍ
                </Link>
              </li>
              <li>
                <Link
                  to="contacto"
                  spy={true}
                  smooth={true}
                  offset={-25}
                  duration={700}
                  className="mobile_menu_navLink"
                  onClick={toggleMenu}
                >
                  CONTACTO
                </Link>
              </li>
            </ul>
            <div className="mobile_menu_footer_top-socialNetworks--socialNetworks">
              <a
                className="facebook"
                rel="noreferrer"
                target="_blank"
                href="https://www.facebook.com/facu.trainerfit"
              >
                <img src={logoFb} alt="Logo Facebook" />
              </a>
              <a
                className="instagram"
                rel="noreferrer"
                target="_blank"
                href="https://www.instagram.com/facu.trainerfit/?igshid=YmMyMTA2M2Y%3D"
              >
                <img src={logoIg} alt="Logo Instagram" />
              </a>
              <a
                className="tiktok"
                rel="noreferrer"
                target="_blank"
                href="https://www.tiktok.com/@facu.trainerfit?_t=8aNLDjqI4LF&_r=1&fbclid=PAAaZHRxDiKy93ku5KAvnpsjRQX-wHLkOve3OP0FABzhTQsF8v5rgzq9geork"
              >
                <img src={logoTt} alt="Logo TikTok" />
              </a>
              <a
                className="youtube"
                rel="noreferrer"
                target="_blank"
                href="https://www.youtube.com/@facu.trainerfit1103"
              >
                <img src={logoYt} alt="Logo Youtube" />
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }

  if (window.scrollY >= window.innerHeight) {
    return (
      <section className="navPosition">
        <div className="position">
          <div className={navbar ? `navContainer-active` : `navContainer`}>
            <img className="navLogoS" src={logo} alt="logo" />
            <ul className="navLista">
              <li>
                <Link
                  to="servicios"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="navLink"
                >
                  SERVICIOS
                </Link>
              </li>
              <li>
                <Link
                  to="sobreMi"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="navLink"
                >
                  SOBRE MÍ
                </Link>
              </li>
              <li>
                <Link
                  to="contacto"
                  spy={true}
                  smooth={true}
                  offset={50}
                  duration={500}
                  className="navLink"
                >
                  CONTACTO
                </Link>
              </li>
            </ul>
          </div>
          <Scroll />
        </div>
      </section>
    );
  }

  return (
    <section className="navPosition">
      <div className="position">
        <div className={navbar ? `navContainer-active` : `navContainer`}>
          <div className="brandContainer">
            <img className="navLogoL" src={logo} alt="logo" />
            <h1 className="navBrand">
              Facu Luque <br />
              <span>TRAINER</span>
            </h1>
          </div>
          <ul className="navLista">
            <li>
              <Link
                to="servicios"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="navLink"
              >
                SERVICIOS
              </Link>
            </li>
            <li>
              <Link
                to="sobreMi"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="navLink"
              >
                SOBRE MÍ
              </Link>
            </li>
            <li>
              <Link
                to="contacto"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                className="navLink"
              >
                CONTACTO
              </Link>
            </li>
          </ul>
        </div>
        <Scroll />
      </div>
    </section>
  );
};

export default Nav;
