import "../Servicios/Servicios.css";
import Button from "../Button/Button";
import Check from "./Assets/icon_check.svg";
import TringuloL from "./Assets/grafico-Imgservicio.svg";
import TringuloR from "./Assets/graficoImg-serviciosR.svg";

const Servicios = ({
  id,
  imagen,
  imagen_mobile,
  title,
  subtitle,
  desc,
  lista,
  left,
  right,
}) => {
  if (window.innerWidth <= 425) {
    return (
      <section className="mobile_servicios" id={id}>
        <div className="mobile_servicios-img">
          <img
            className="serviciosImagen"
            src={require(`${imagen_mobile}`)}
            alt="imagen"
          />
          <img
            className="serviciosTrianguloL"
            src={TringuloL}
            alt="triangulo"
          />
        </div>

        <div className="mobile_serviciosFlex">
          <h3 className="mobile_serviciosTitle">{title}</h3>
          <h4
            className="mobile_serviciosSubtitle"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
          <p
            className="mobile_serviciosText"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
          <ul className="mobile_serviciosLista">
            {lista.map((item) => (
              <li key={item}>
                <img
                  className="serviciosLista_lista-icon"
                  src={Check}
                  alt="Check Icon"
                />{" "}
                {item}
              </li>
            ))}
          </ul>
          <div className="buttonContainer">
            <Button />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="servicios" id={id}>
      {left && (
        <div className="servicios-img">
          <img
            className="serviciosImagen"
            src={require(`${imagen}`)}
            alt="imagen"
          />
          <img
            className="serviciosTrianguloL"
            src={TringuloL}
            alt="triangulo"
          />
        </div>
      )}
      <div className="serviciosFlex">
        <h3 className="serviciosTitle">{title}</h3>
        <h4
          className="serviciosSubtitle"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <p
          className="serviciosText"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
        <ul className="serviciosLista">
          {lista.map((item) => (
            <li>
              <img
                className="serviciosLista_lista-icon"
                src={Check}
                alt="Check Icon"
              />{" "}
              {item}
            </li>
          ))}
        </ul>
        <div className="buttonContainer">
          <Button />
        </div>
      </div>
      {right && (
        <div className="servicios-img">
          <img
            className="serviciosImagen"
            src={require(`${imagen}`)}
            alt="imagen"
          />
          <img
            className="serviciosTrianguloR"
            src={TringuloR}
            alt="triangulo"
          />
        </div>
      )}
    </section>
  );
};

export default Servicios;
