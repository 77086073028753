import "./Contacto.css"
import contactoDesktop from "./Assets/img-footer.jpg"
import contactoMobile from "./Assets/contacto.png"
import Button from "../Button/Button"
import play from "../Button/Assets/play.png"

const Contacto = () => {

    if (window.innerWidth <= 425) {
        return (
            <section className="contacto_container" id="contacto">
                <div className="mobile_contacto">
                    <div className="mobile_contacto_background">
                        <img className="mobile_contacto_background-img" src={contactoMobile} alt="Contacto Desktop" />
                    </div>
                    <div className="mobile_contacto_content">
                        <h3 className="mobile_contacto_content-title">¡Te ayudo a cumplir tus objetivos!</h3>
                        <p className="mobile_contacto_content-description">Si tenés preguntas, no dudes en enviarme un mensaje</p>
                        <div>
                            <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5491160467063" className="mobile_btnL">
                                <p className="mobile_btnL_content">QUIERO COMENZAR</p>
                                <img className="mobile_btnL_icon" src={play} alt="Logo play" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="contacto_container" id="contacto">
            <div className="contacto">
                <div className="contacto_background">
                    <img className="contacto_background-img" src={contactoDesktop} alt="Contacto Desktop" />
                </div>
                <div className="contacto_content">
                    <h3 className="contacto_content-title">¡Te ayudo a cumplir tus objetivos!</h3>
                    <p className="contacto_content-description">Si tenés preguntas, no dudes en enviarme un mensaje</p>
                    <Button />
                </div>
            </div>
        </section>
    )
}
export default Contacto