import "./SobreMi.css";
import facu from "./Assets/facu.png";
import trianguloBlanco from "./Assets/trianguloabajo-sobremi.svg";
import trianguloRojo from "./Assets/trianguloarriba-sobremi.svg";
import Slider from "../Slider/Slider";

const SobreMi = () => {

  if (window.innerWidth <= 425) {
    return (
      <section className="sobreMi_container" id="sobreMi">
        <div className="sobreMi_stick"></div>
        <div className="mobile_sobreMi">
          <img className="mobile_top_triangle" src={trianguloRojo} alt="triangulo" />
          <img
            className="mobile_bottom_triangle"
            src={trianguloBlanco}
            alt="triangulo"
          />
          <img className="mobile_facu" src={facu} alt="Facu" />
          <div className="mobile_sobreMi_content">
            <h2 className="mobile_sobreMi_content-title">
              HOLA, SOY
              <br /> <span>FACU LUQUE</span>
            </h2>
            <p className="mobile_sobreMi_content-description">
              Preparador Físico de Alto Rendimiento y Personal Trainer,
              especializado en fútbol, rugby y otros tipos de entrenamiento
              deportivos.
            </p>
            <p className="mobile_sobreMi_content-description">
              Mi objetivo es diseñar un programa de entrenamiento personalizado
              basado en tus necesidades específicas, ya sea para prepararte para
              un gran competencia deportiva o estar en forma.
            </p>
            <div className="sobreMi_content-certifications">
              <h3 className="mobile_sobreMi_content-certifications--title">
                CERTIFICACIONES
              </h3>
            </div>
            <div className="mobile_sliderContainer">
              <Slider />
            </div>
          </div>
        </div>
        <div className="sobreMi_stick"></div>
      </section>
    );
  }

  return (
    <section className="sobreMi_container" id="sobreMi">
      <div className="sobreMi_stick"></div>
      <div className="sobreMi">
        <img className="top_triangle" src={trianguloRojo} alt="triangulo" />
        <img
          className="bottom_triangle"
          src={trianguloBlanco}
          alt="triangulo"
        />
        <img className="facu" src={facu} alt="Facu" />
        <div className="sobreMi_content">
          <h2 className="sobreMi_content-title">
            HOLA, SOY
            <br /> <span>FACU LUQUE</span>
          </h2>
          <p className="sobreMi_content-description">
            Preparador Físico de Alto Rendimiento y Personal Trainer,
            especializado en fútbol, rugby y otros tipos de entrenamiento
            deportivos.
          </p>
          <p className="sobreMi_content-description">
            Mi objetivo es diseñar un programa de entrenamiento personalizado
            basado en tus necesidades específicas, ya sea para prepararte para
            un gran competencia deportiva o estar en forma.
          </p>
          <div className="sobreMi_content-certifications">
            <span></span>
            <h3 className="sobreMi_content-certifications--title">
              CERTIFICACIONES
            </h3>
            <span></span>
          </div>
          <div className="sliderContainer">
            <Slider />
          </div>
        </div>
      </div>
      <div className="sobreMi_stick"></div>
    </section>
  );
};
export default SobreMi;
